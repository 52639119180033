 import React from 'react';
import SEO from 'src/components/seo';
import cs from 'classnames';
import styles from 'src/pages/rasy-psow.module.sass';
import BigArticleTitle from 'src/components/shared/texts/BigArticleTitle/BigArticleTitle';
import Layout from 'src/Theme/layout';
import { graphql } from 'gatsby';
import DogBreeds from 'src/components/pages/dogBreeds/DogBreeds/DogBreeds';

const rasyPsow = ({ data }) => {
const { allDatoCmsDogBreed: { nodes: breeds }, allDatoCmsPodcast: {nodes: podcasts}, allDatoCmsArticle: {nodes: articles}} = data;
  return (
    <Layout>
      <SEO title="Rasy psów"/>
      <div className={cs(styles.dogsWrap, 'firstSectionWrap')}>
        <BigArticleTitle className={styles.title}>Rasy psów</BigArticleTitle>
        <DogBreeds breeds={breeds} podcasts={podcasts} articles={articles}/>
      </div>
    </Layout>
  );
};
export const query = graphql`
    {
        allDatoCmsDogBreed {
            nodes {
                breed
                id
            }
        }

        allDatoCmsPodcast {
            nodes {
                breeds {
                    breed{
                        breed
                    }
                }
            }
        }
        allDatoCmsArticle {
            nodes {
                breeds {
                    breed{
                        breed
                    }
                }
            }
        }
    }
`;

export default rasyPsow;